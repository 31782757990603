import { request } from './request'

/*
* 阿里授权入口
*/
export function alibaba (data) {
  return request({
    url: '/api/rukou/alibaba',
    method: 'post',
    data
  })
}

/*
* 创源商品
*/
export function chuangyuanshangpin (data) {
  return request({
    url: '/api/rukou/chuangyuanshangpin',
    method: 'post',
    data
  })
}
